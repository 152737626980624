require("turbolinks").start();
require("jquery")
require("@rails/activestorage").start();
// require("jquery-ui")

require('packs/air-datepicker/dist/js/datepicker');
import 'packs/air-datepicker/dist/css/datepicker';

// query string parameters
$(document).on("turbolinks:load", function () {
  if ($('#calendar')[0]) {
    const urlParams = new URLSearchParams(window.location.search);
    const type = urlParams.get('type');

    $('#calendar').datepicker({
      inline: true,
      firstDay: 1,
      showOtherMonths: true,
      dayNamesMin: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
      onRenderCell: (date, cellType) => {
        return calendarEvent(date, type);
      }
    });
    console.log('options', $('#calendar').datepicker('option'));
  }

  function calendarEvent(date, type) {
    let day = date.getDate();
    let month = date.getMonth() + 1;
    let year = date.getFullYear();
    let events = [];

    $.ajax({
      method: 'GET',
      url: `events_by_date?date=${day} ${month} ${year}&type=${type}`, // type
      async: false,
      success: (data) => {
        events = data;
      }
    });
    if (events.length == 0) {
      return { disabled: true };
    }

    let event_block = $('.calendar-event__wrapper').clone()[0];
    let list = event_block.querySelector('ul');
    events.forEach((event) => {
      let list_line = $('.calendar-event').clone()[0];
      list_line.querySelector('.event-title').href = event.link;
      list_line.querySelector('.event-title').textContent = event.title;
      list_line.querySelector('.event-time').textContent = event.time;
      list.append(list_line);
    });
    return {
      html: day + event_block.outerHTML,
      classes: 'has_events'
    };
  }
});