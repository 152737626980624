// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("turbolinks").start();
require("jquery")
require("@rails/activestorage").start();
require("jquery-ui")

require('packs/air-datepicker/calendar');

import salert from 'sweetalert2'

import $ from 'jquery';
import {} from 'jquery-ujs';

const formatTime = (element) => {
  return element.textContent = moment(element.dataset['date'], "YYYY-MM-DD").fromNow()
}
const formatFullTime = (element) => {
  return element.textContent = moment(element.dataset['date'], "YYYY-MM-DD").format('YYYY.MM.DD')
}

$(document).on("turbolinks:load", function () {
  let searchIn = document.querySelectorAll('.header-search__mobile'),
    searchOverlay = document.querySelector('.search-mobile'),
    closeBtn = document.querySelector('.close');

  searchIn.forEach(el => {
    el.addEventListener('click', function () {
      searchOverlay.classList.add('visible');
    })
  });

  closeBtn.addEventListener('click', function () {
    searchOverlay.classList.remove('visible');
  });



  // slider
  let mainSlider = document.querySelector('.slider__wrapper')

  if (mainSlider) {
    let items = document.querySelectorAll('.slider-item');
    let current = 0;

    function slider() {
      for (let i = 0; i < items.length; i++) {
        items[i].classList.add('opacity0');
      }
      items[current].classList.remove('opacity0');
      if (current + 1 == items.length) {
        current = 0;
      } else {
        current++
      }
    }

    let slideIndex = 1,
      slides = document.querySelectorAll('.slider-item'),
      prev = document.querySelector('.slider-prev'),
      next = document.querySelector('.slider-next'),
      dotsWrap = document.querySelector('.slider-dots'),
      dots = document.querySelectorAll('.dot');

    showSlides(slideIndex);

    function showSlides(n) {
      if (n > slides.length) {
        slideIndex = 1;
      }
      if (n < 1) {
        slideIndex = slides.length;
      }

      slides.forEach((item) => item.style.display = 'none');
      dots.forEach((item) => item.classList.remove('dot-active'));

      slides[slideIndex - 1].style.display = "block";
      dots[slideIndex - 1].classList.add('dot-active');
    }

    function plusSlides(n) {
      showSlides(slideIndex += n);
    }

    function currentSlide(n) {
      showSlides(slideIndex = n);
    }

    prev.addEventListener('click', function () {
      plusSlides(-1);
    });
    next.addEventListener('click', function () {
      plusSlides(1);
    });

    dotsWrap.addEventListener('click', function (event) {
      for (let i = 0; i < dots.length + 1; i++) {
        if (event.target.classList.contains('dot') && event.target == dots[i - 1]) {
          currentSlide(i);
        }
      }
    });
  }

  // const $timeElems = Array.from(document.getElementsByClassName('jsDate'))
  // $timeElems.map(e => formatTime(e))

  // const $fullTimeElems = Array.from(document.getElementsByClassName('jsFullDate'))
  // $fullTimeElems.map(e => formatFullTime(e))

  $('.jsClosePopup').click(e => {
    $(`.${e.target.dataset.class}`).removeClass('visible')
  })

  $('.jsOpenPopup').click(e => {
    $(`.${e.target.dataset.class}`).addClass('visible')
  })

  $('.modal').click(function (e) {
    if ($(e.target).hasClass('modal')) {
      $(this).removeClass('visible')
    }
  })

  $("#feedbackForm").on('submit', function (e) {
    e.preventDefault()
    $.ajax({
      method: 'POST',
      url: '/forms/feedback',
      data: $(this).serialize(),
      success: () => {
        new salert({
          type: 'success',
          title: 'Спасибо за заявку!'
        });
      },
      error: (e) => {
        new salert({
          type: 'error',
          title: 'Ошибка',
          text: "Произошла ошибка при отправке формы",
        });
      }
    });
  });

  $("#newsForm").on('submit', function (e) {
    e.preventDefault()
    $.ajax({
      method: 'POST',
      url: '/forms/suggest_news',
      data: $(this).serialize(),
      success: () => {
        new salert({
          type: 'success',
          title: 'Новость отправлена!'
        });
      },
      error: (e) => {
        new salert({
          type: 'error',
          title: 'Ошибка',
          text: "Произошла ошибка при отправке формы",
        })
      }
    })
  })


  // comments

  // let commentWrap = document.querySelector('.form-comment__wrap');
  // let addComment = document.querySelector('.comment-add');
  // let commentReply = document.querySelector('.comment-reply')

  // addComment.addEventListener('click', function () {
  //   commentWrap.classList.add('show')
  // })

  // commentReply.addEventListener('click', function () {
  //   commentWrap.classList.add('show')
  // })


  $("#subscribeForm").on('submit', function (e) {
    e.preventDefault();

    const formattedData = $(this).serializeArray().reduce((store, item) => {
      console.log(store, item)
      if (!store[item.name]) {
        // store[item.name] = item.name === 'subscribe[email]' ? JSON.parse(item.value) : item.value
        store[item.name] = item.value
      }

      return store
    }, {})

    $.ajax({
      method: 'POST',
      url: '/forms/subscribe',
      data: formattedData,
      success: () => {
        new salert({
          type: 'success',
          title: 'Спасибо за подписку!'
        });
      },
      error: (e) => {
        new salert({
          type: 'error',
          title: 'Ошибка',
          text: "Произошла ошибка при отправке формы",
        })
      }
    })
  })


  // burger-menu
  let burger = document.querySelector('.menu-btn');
  let navMobile = document.querySelector('.nav-mobile')

  burger.addEventListener("click", function (e) {
    e.preventDefault();
    this.classList.toggle('menu-btn_active');
    navMobile.classList.toggle('menu_active');
    document.getElementsByTagName('body')[0].classList.toggle('bodyBlock')
  })

  function timeConverter(UNIX_timestamp) {
    let a = new Date(UNIX_timestamp * 1000);
    let months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    let year = a.getFullYear();
    let month = months[a.getMonth()];
    let date = a.getDate();
    let hour = a.getHours();
    let min = a.getMinutes();
    let sec = a.getSeconds();
    let time = date + ' ' + month + ' ' + year + ' ' + hour + ':' + min + ':' + sec;
    return time;
  }

  // mobile-search
  $('.header-mobile-search-logo').click(function (e) {
    $('.header-mobile-search__wrapper').addClass("mobile-search-active")
  })

  $('.search-cross').click(function (e) {
    $('.header-mobile-search__wrapper').removeClass("mobile-search-active")
  })

  //switch theme

  // window.addEventListener('storage', function (e) {
  //   console.log('change')
  // })

  // $('.themes').click(function (e) {

  //   $('body').toggleClass('light-theme');
  //   localStorage.setItem('data', $('body'))
  // })

  function theme() {
    const toggleTheme = document.querySelector('.themes');
    let body = document.querySelector('body');

    toggleTheme.addEventListener("click", () => {
      if (body.classList.contains('light-theme')) {
        body.classList.remove('light-theme');
        localStorage.removeItem('light-theme', 'light')
      } else {
        body.classList.add('light-theme')
        localStorage.setItem('light-theme', 'light')
      }

    })
    if (localStorage.getItem('light-theme') !== null) {
      body.classList.add('light-theme')
    }
  }


  theme()

  // localStorage.setItem('data', '.themes')
  // console.log(localStorage.getItem('data'))

  // cookie
  function setCookie(name, value, options = {}) {
    options = {
      path: '/',
      // при необходимости добавьте другие значения по умолчанию
      ...options
    };

    if (options.expires.toUTCString) {
      options.expires = options.expires.toUTCString();
    }

    let updatedCookie = encodeURIComponent(name) + "=" + encodeURIComponent(value);

    for (let optionKey in options) {
      updatedCookie += "; " + optionKey;
      let optionValue = options[optionKey];
      if (optionValue !== true) {
        updatedCookie += "=" + optionValue;
      }
    }

    document.cookie = updatedCookie;
  }

  function getCookie(name) {
    let matches = document.cookie.match(new RegExp(
      "(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
    ));
    return matches ? decodeURIComponent(matches[1]) : undefined;
  }

  const cookieElement = document.querySelector('.cookie')
  if (cookieElement) {
    const isCookie = getCookie('isCookie')

    function hideCookie() {
      cookieElement.classList.add('hidden')
    }

    if (isCookie) {
      hideCookie()
    } else {
      cookieElement.classList.remove('hidden')
    }

    const confirmCookieElement = document.querySelector('.cookie__button')
    confirmCookieElement.addEventListener('click', () => {
      let date = new Date(Date.now() + 86400e3);
      setCookie('isCookie', 'true', {
        'expires': date
      })
      hideCookie()
    })
  }

  // svg
  $('img').each(function () {
    var $img = $(this);
    var imgClass = $img.attr('class');
    var imgURL = $img.attr('src');
    $.get(imgURL, function (data) {
      var $svg = $(data).find('svg');
      if (typeof imgClass !== 'undefined') {
        $svg = $svg.attr('class', imgClass + ' replaced-svg');
      }
      $svg = $svg.removeAttr('xmlns:a');
      if (!$svg.attr('viewBox') && $svg.attr('height') && $svg.attr('width')) {
        $svg.attr('viewBox', '0 0 ' + $svg.attr('height') + ' ' + $svg.attr('width'))
      }
      $img.replaceWith($svg);
    }, 'xml');
  });



  // calendar

  let calendar = document.querySelector('.modal-calendar');
  if (calendar) {
    let calendarBtn = document.querySelector('.calendar-btn');
    let calendarOverlay = document.querySelector('.modal-calendar');
    let calendarClose = document.querySelector('.calendar-close');

    calendarBtn.addEventListener("click", function () {
      calendarOverlay.classList.add('show-calendar');
    })

    calendarClose.addEventListener('click', function () {
      calendarOverlay.classList.remove('show-calendar');
    })
  }

  const mounthes = document.querySelectorAll('.calendar-month');
  // console.log('привет', mounthes)
  // mounthes.classList.add('january')
  mounthes.forEach((element) => {
    if (mounthes[2]) {
      mounthes[2].classList.add('january')

    }
  });



  // socials-hide
  let showBtn = document.querySelectorAll('.news-item_share');
  let socialsWrap = document.querySelectorAll('.socials-hide__wrapper');

  for (let i = 0; i < showBtn.length; i++) {
    showBtn[i].addEventListener('click', function () {
      console.log('socialsWrap[i]', socialsWrap[i])
      socialsWrap[i].classList.toggle('active');

    })

  }
});

require("channels");

console.log('load packs')



// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)